import { useState } from 'react';
import gql from 'graphql-tag';
import queryString from 'query-string';
import {
  FeaturedProducts,
  SearchBox,
  SearchProducts,
  StoreFilters,
  CustomFilter,
  ProductNavigator,
  RecentProducts,
} from '../../src/common/components';

function HomePage({ pageProps }) {
  const [filtersSidebarOpen, setFiltersSidebarOpen] = useState(false);
  const closeFiltersSidebar = () => setFiltersSidebarOpen(false);

  return (
    <div className="relativeContainer">
      <div className="store">
        <ProductNavigator />
        <div className="store__content">
          <SearchBox menuItems={pageProps.menuItems} />
          <h6 className="textMargin alignRight">
            <i className="fas fa-star fa-fw starIcon" /> = Customizable product
          </h6>
          <CustomFilter />
          {pageProps.error && (
            <h3
              style={{
                textAlign: 'center',
                marginTop: '4em',
                color: 'red',
                border: '1px solid red',
                borderRadius: 2,
                padding: '1em 2em',
              }}
            >
              Error Occured. Please refresh the page.
            </h3>
          )}
          {!pageProps.error && (
            <>
              <SearchProducts />
              <FeaturedProducts products={pageProps.featuredProducts} />
              <RecentProducts />
            </>
          )}
        </div>
      </div>
      {filtersSidebarOpen && (
        <>
          <div className="store__mobileFilters ">
            <StoreFilters />
          </div>
          <div className="store__mobileFilters__scrim " onClick={() => closeFiltersSidebar()}></div>
        </>
      )}
    </div>
  );
}

export default HomePage;

HomePage.getInitialProps = async (ctx) => {
  const pageProps = {};
  try {
    const accessCode = ctx.query.accessCode;

    if (accessCode) {
      let query = queryString.parseUrl(ctx.asPath)?.query;
      let varsFromQuery = {
        storeGroupFilter: query?.filters?.split('-') ?? [],
      };
      if (query.custom) {
        varsFromQuery.customizable = query.custom === 'only';
      }
      const { data: { featuredProducts: { edges: featuredProducts = [] } = {} } = {} } = await ctx.apolloClient.query({
        query: GET_HOME_PAGE_FEATURED_PRODUCTS,
        variables: {
          code: accessCode,
          ...varsFromQuery,
        },
      });
      pageProps.featuredProducts = featuredProducts.map(({ node }) => node);
    }
  } catch (error) {
    pageProps.error = error;
  }

  return { pageProps };
};

export const GET_HOME_PAGE_FEATURED_PRODUCTS = gql`
  query GET_HOME_PAGE_FEATURED_PRODUCTS($code: String!, $customizable: Boolean, $storeGroupFilter: [ID]) {
    featuredProducts: storeFeaturedProducts(
      code: $code
      isCustomizable: $customizable
      storeGroupFilter: $storeGroupFilter
    ) {
      edges {
        node {
          id
          pk
          isVariable
          group {
            id
            pk
            name
          }
          shape {
            id
            productViewList
            defaultVariation {
              id
              pk
              getOnlinePrice
            }
          }
        }
      }
    }
  }
`;
